<template>
  <div class="flex justify-center">
    <div class="w-2/3">
      <div class="flex items-center mt-10">
        <i class="el-icon-arrow-left font-bold text-xl cursor-pointer" @click="goBack" />
        <p class="text-xl ml-4 font-bold" style="color: #ff8e3b">Subscription Order</p>
      </div>
      <div class="pay-info" style="width: 100%;padding: 15px 20px;box-sizing: border-box;margin-top: 25px;">
        <div class="flex justify-between font-bold text-lg">
          <p>Program</p>
          <p style=" color: var(--c-primary);">{{ applicationInfo.project_info.project_name }}</p>
        </div>
        <div class="flex justify-between text-lg my-4">
          <p>Subscription Period</p>
          <p style="font-weight: normal;">{{ start_date }} to {{ end_date }}</p>
        </div>
        <div class="flex justify-between text-lg my-4">
          <p>Standard Subscription Fee</p>
          <p style="font-weight: bold;color: #ff8e3b;">{{ currency }} {{ standard_fee
            }}</p>
        </div>

        <div class="flex justify-between text-lg my-4">
          <p>Applicable Fee Reduction</p>
          <p style="font-weight: bold;color: #ff8e3b;">{{ currency }} {{ calcReductionFee() }}</p>
        </div>
        <div class="flex justify-between text-lg my-4">
          <p>Payment Service Fee</p>
          <p style="font-weight: bold;color: #ff8e3b">{{ currency }} {{ serviceFee }}</p>
        </div>

        <div style="width: 100%;height: 1px;background-color: var(--c-primary);"> </div>

        <div class="flex justify-between text-lg my-4 font-bold">
          <p>Total Order Amount</p>
          <p style="color: #ff8e3b">{{ currency }} {{ amount }}</p>
        </div>
        <p style="font-style: italic;">*The service fee is charged by the payment platform. It is not
          included in the registration fee.</p>
      </div>
      <div style="margin-top: 15px;">
        <p class="text-xl ml-4 font-bold">Terms and Conditions</p>
        <p style="margin-top: 10px;line-height: 24px;font-size: 16px;background-color:#fff5f4;width: 100%;padding: 15px 20px;box-sizing: border-box;"
          v-html="project_refund_policy">
        </p>
      </div>
      <div class="terms flex items-center justify-center" style="margin-top: 20px;">
        <div @click="isAgree = !isAgree" style="display: flex; align-items: center">
          <img style="width: 16px;height: 16px;cursor: pointer;margin-right: 16px;" v-if="!isAgree"
            :src="require('../../assets/image/unchecked.png')" />
          <img style="width: 16px;height: 16px;cursor: pointer;margin-right: 16px;" v-else
            :src="require('../../assets/image/checked.png')" />
        </div>
        <p> I have read and agree on the terms and conditions.</p>
      </div>
      <div class="w-full flex justify-center items-center" style="margin: 30px 0px;">
        <el-button class="btn" @click="goBack">Cancel</el-button>
        <el-button class="btn" type="primary" @click="goStripe">Pay</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { checkApplication } from "../../api/eae";
export default {
  name: "Payment",
  data() {
    return {
      application_id: this.$route.query.id,
      applicationInfo: {},
      project_refund_policy: '',
      start_date: '',
      end_date: '',

      amount: 0,
      serviceFee: 0.00,
      standard_fee: 0,
      currency: 'USD',

      isAgree: false
    };
  },
  mounted() {
    let date = new Date()
    this.start_date = this.convertTimeToEng(date.getTime())
    date.setFullYear(date.getFullYear() + 1)
    var nextYearTimestamp = date.getTime() - 24 * 60 * 60 * 1000 //前一天
    this.end_date = this.convertTimeToEng(nextYearTimestamp)
    this.fetchData();
  },
  methods: {

    calcReductionFee() {
      let fee = this.standard_fee - (this.applicationInfo.total_price)
      return '(' + fee + ')'
    },

    async fetchData() {
      const result = await checkApplication(this.application_id);
      this.applicationInfo = result.data.data
      if (this.applicationInfo.application_status == '已缴费' || this.applicationInfo.application_status == '无需缴费') {
        this.goBack()
        return
      }


      this.currency = this.applicationInfo.project_info.project_price_currency
      this.serviceFee = this.applicationInfo.service_fee
      this.standard_fee = this.applicationInfo.standard_fee
      this.amount = this.applicationInfo.service_fee + this.applicationInfo.total_price

      this.project_refund_policy = this.applicationInfo.project_info.project_refund_policy
      this.project_refund_policy = this.project_refund_policy.replaceAll('\r\n\r\n', '<br><br>')
    },
    goBack() {
      this.$router.push({
        path: "/home/registration",
      });
    },

    goStripe() {
      if (!this.isAgree) {
        this.$message.warning("Please read terms and agree it");
        return;
      }
      let url = this.$router.resolve({
        path: "/program-pay",
        query: {
          application_id: this.application_id,
        },
      });
      window.open(url.href, "_blank");
    },
    convertTimeToEng(time) {
      var date = new Date(time);
      let year = date.getFullYear();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      return this.formatMonthToEng(month) + " " + day + ", " + year;
    },

    formatMonthToEng(month) {
      switch (month) {
        case 1:
          return "January";
        case 2:
          return "February";
        case 3:
          return "March";
        case 4:
          return "April";
        case 5:
          return "May";
        case 6:
          return "June";
        case 7:
          return "July";
        case 8:
          return "August";
        case 9:
          return "September";
        case 10:
          return "October";
        case 11:
          return "November";
        case 12:
          return "December";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pay-info {
  background: #fff5f4;
  color: #7F7F7F
}

.btn {
  width: 160px;
}

.pay-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.QRcode {
  width: 220px;
  height: 200px;
  background: url("../../assets/image/qrcode.png");
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  margin-top: 20px;

  .text {
    width: 100%;
    word-break: break-word;
    text-align: center;
  }
}
</style>